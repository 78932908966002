var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { smallConditions as conditions } from "./default";
var PLAYER = 'o';
var OPPONENT = 'x';
var MAX = 1000;
var MIN = -1000;
var alg_moves = 0;
var calc_move = function (board) {
    alg_moves = 0;
    var scores = gen_boards(board, PLAYER).map(function (move) { return ({
        move: move,
        score: miniMax(move.board, 0, false),
    }); });
    var bestScore = scores.reduce(function (max, score) { return Math.max(max, score.score); }, MIN);
    var bestMoves = scores.reduce(function (arr, score) {
        if (score.score === bestScore) {
            arr.push(score.move.to);
        }
        return arr;
    }, []);
    var randMove = bestMoves[Math.floor(Math.random() * bestMoves.length)];
    return Promise.resolve({ to: randMove, moves: alg_moves });
};
export var gen_boards = function (board, mover) {
    var initialArray = [];
    return board.reduce(function (array, pos, i) {
        if (pos === null) {
            var validBoard = __spreadArray([], board, true);
            validBoard[i] = mover;
            array.push({ to: i, board: validBoard });
        }
        return array;
    }, initialArray);
};
var miniMax = function (board, depth, isMax) {
    alg_moves += 1;
    var score = evalBoard(board);
    if (score !== 0) {
        return score === 100
            ? score - depth
            : score + depth;
    }
    if (isDraw(board)) {
        return 0;
    }
    return isMax
        ? Math.max.apply(Math, __spreadArray([MIN], gen_boards(board, PLAYER).map(function (board) { return miniMax(board.board, depth + 1, false); }), false)) : Math.min.apply(Math, __spreadArray([MAX], gen_boards(board, OPPONENT).map(function (board) { return miniMax(board.board, depth + 1, true); }), false));
};
var evalBoard = function (board) {
    if (conditions.some(function (condition) { return condition.every(function (pos) { return board[pos] === PLAYER; }); })) {
        return 100;
    }
    else if (conditions.some(function (condition) { return condition.every(function (pos) { return board[pos] === OPPONENT; }); })) {
        return -100;
    }
    return 0;
};
var isDraw = function (board) {
    return board.every(function (position) { return position !== null; });
};
export default calc_move;
