var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { smallConditions, bigConditions } from "./default";
import { gen_boards } from "./3x3minimax";
var awsURL = 'https://09y3qsciu8.execute-api.us-east-1.amazonaws.com/ab';
var defaultBorders = {
    top: true,
    right: true,
    bottom: true,
    left: true
};
var corners = [0, 4, 20, 24];
export var genSpaces = function (board, size, click, pvp, draw) {
    return board.map(function (player, i) { return ({
        key: (size === 'small' ? 's-' : 'l-') + i.toString(10),
        player: player,
        position: i,
        winPos: false,
        click: click,
        borders: __assign({}, defaultBorders),
        size: size,
        pvp: pvp,
        draw: draw
    }); });
};
export var borderClasses = function (borders, size) {
    var classes = [];
    if (!borders.top) {
        classes.push('top');
    }
    if (!borders.right) {
        classes.push('right');
    }
    if (!borders.bottom) {
        classes.push('bottom');
    }
    if (!borders.left) {
        classes.push('left');
    }
    return classes.join(' ');
};
export var smallMap = function (i) {
    var borders = __assign({}, defaultBorders);
    if (i % 3 === 0) {
        borders.left = false;
    }
    if (i % 3 === 2) {
        borders.right = false;
    }
    if (i < 3) {
        borders.top = false;
    }
    if (i > 5) {
        borders.bottom = false;
    }
    return borders;
};
export var bigMap = function (i) {
    var borders = __assign({}, defaultBorders);
    if (i % 5 === 0) {
        borders.left = false;
    }
    if (i % 5 === 4) {
        borders.right = false;
    }
    if (i < 5) {
        borders.top = false;
    }
    if (i > 19) {
        borders.bottom = false;
    }
    return borders;
};
export var toArray = function (objects, size) {
    var arr = Array(size === 'small' ? 9 : 25);
    objects.forEach(function (obj) {
        arr[obj.position] = obj.player;
    });
    return arr;
};
export var gameOver = function (board, size) {
    var conditions = size === 'small' ? smallConditions : bigConditions;
    var results = {
        over: false,
        winner: null,
        wins: [],
        started: true
    };
    conditions.forEach(function (condition) {
        if (board[condition[0]] &&
            condition.every(function (space) { return board[space] === board[condition[0]]; })) {
            results.winner = board[condition[0]];
            results.wins.push(condition);
            results.over = true;
        }
    });
    if (board.every(function (pos) { return pos; })) {
        results.over = true;
    }
    return results;
};
export var blankBoard = function (size, mode) {
    var board = [];
    for (var i = 0; i < (size === 'small' ? 9 : 25); i++) {
        var obj = {
            winPos: false,
            player: null,
            position: i,
            click: null,
            borders: __assign({}, defaultBorders),
            size: size,
            pvp: mode === 'pvp',
            draw: false
        };
        board.push(obj);
    }
    return board;
};
export var aws_alg = function (board) { return __awaiter(void 0, void 0, void 0, function () {
    var quickMoves, openCorners, quickMove, body, response, validMoves, randMove, data, move, checked;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (board[12] === null) {
                    return [2 /*return*/, { to: 12, moves: 1 }];
                }
                quickMoves = 0;
                openCorners = [];
                corners.forEach(function (i) {
                    if (board[i] === null) {
                        quickMoves += 1;
                        openCorners.push(i);
                    }
                });
                if (openCorners.length > 0) {
                    quickMove = openCorners[Math.floor(Math.random() * openCorners.length)];
                    return [2 /*return*/, { to: quickMove, moves: quickMove }];
                }
                body = { board: board };
                return [4 /*yield*/, fetch(awsURL, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(body)
                    })];
            case 1:
                response = _a.sent();
                if (response.status !== 200) {
                    validMoves = gen_boards(board, 'o');
                    randMove = validMoves[Math.floor(Math.random() * validMoves.length)];
                    return [2 /*return*/, { to: randMove.to, moves: 1 }];
                }
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                move = data.moves[Math.floor(Math.random() * data.moves.length)];
                checked = data.checked;
                return [2 /*return*/, { to: move.to, moves: checked }];
        }
    });
}); };
