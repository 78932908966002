var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { gameOver, blankBoard, toArray, aws_alg } from "../helpers";
import Options from "./Options";
import smallAlg from '../3x3minimax';
import Space from "./Space";
import { toast } from "react-toastify";
import { Spinner, Modal } from "react-bootstrap";
var defaultStatus = { over: false, winner: null, wins: [[]], started: false };
var Board = function () {
    var _a = useState(null), board = _a[0], setBoard = _a[1];
    var _b = useState('small'), size = _b[0], setSize = _b[1];
    var _c = useState('pvp'), mode = _c[0], setMode = _c[1];
    var _d = useState('x'), player = _d[0], setPlayer = _d[1];
    var _e = useState(defaultStatus), gameStatus = _e[0], setStatus = _e[1];
    var _f = useState(false), thinking = _f[0], setThinking = _f[1];
    useEffect(function () {
        reset();
    }, []);
    var reset = function () {
        setBoard(blankBoard(size, mode));
        setPlayer('x');
        setStatus(defaultStatus);
        toast.dismiss();
    };
    var changeOptions = function (opts) {
        if (opts.mode !== mode) {
            setMode(opts.mode);
        }
        if (opts.size !== size) {
            setSize(opts.size);
        }
    };
    useEffect(function () {
        reset();
    }, [size, mode]);
    var makeMove = function (pos) {
        var tempBoard = __spreadArray([], board, true);
        tempBoard[pos].player = player;
        var status = gameOver(toArray(tempBoard, size), size);
        var newBoard = [];
        tempBoard.forEach(function (obj) {
            var newObj = __assign({}, obj);
            if (newObj.position === pos) {
                newObj.player = player;
            }
            if (status.over) {
                if (!status.winner) {
                    newObj.draw = true;
                    newBoard.push(newObj);
                    return;
                }
                else {
                    for (var _i = 0, _a = status.wins; _i < _a.length; _i++) {
                        var win = _a[_i];
                        if (win.some(function (ind) { return ind === newObj.position; })) {
                            newObj.winPos = true;
                        }
                    }
                }
            }
            newBoard.push(newObj);
        });
        setBoard(newBoard);
        setPlayer(player === 'x' ? 'o' : 'x');
        setStatus(status);
    };
    var click = function (pos) {
        if (gameStatus.over || board.find(function (p) { return p.position === pos; }).player || thinking || mode === 'ai' && player === 'o') {
            return;
        }
        toast.dismiss();
        makeMove(pos);
    };
    useEffect(function () {
        if (gameStatus.over || player === 'x' || mode === 'pvp') {
            return;
        }
        var boardArray = toArray(board, size);
        var alg_function = size === 'small' ? smallAlg : aws_alg;
        if (size === 'large') {
            setThinking(true);
        }
        alg_function(boardArray).then(function (move) {
            makeMove(move.to);
            toast("The computer moved to position ".concat(move.to, "\nafter analyzing ").concat(move.moves.toLocaleString(), " moves."));
            if (size === 'large') {
                setThinking(false);
            }
        });
    }, [player]);
    var thinker = (React.createElement("div", { className: 'thinker' },
        React.createElement("span", { className: 'think-text' }, "Thinking..."),
        React.createElement(Spinner, { animation: 'grow', className: 'spin', variant: 'primary' }),
        React.createElement("img", { className: 'aws-logo', src: "https://d0.awsstatic.com/logos/powered-by-aws.png", alt: "Powered by AWS Cloud Computing" })));
    var modal = (React.createElement(Modal, { centered: true, dialogClassName: "modal-50w", show: thinking, backdrop: 'static', keyboard: false }, thinker));
    return (React.createElement(React.Fragment, null,
        React.createElement(Options, { change: changeOptions, reset: reset, started: gameStatus.started }),
        React.createElement("div", { className: size }, board ? board.map(function (space, i) {
            space.click = click;
            return React.createElement(Space, __assign({}, space, { key: 's-' + i }));
        }) : null),
        modal));
};
export default Board;
