import React from "react";
import { toast } from "react-toastify";
import { Button, Navbar } from 'react-bootstrap';
var Nav = function () {
    var demoToast = function () {
        toast('Login disabled in Demo Mode');
    };
    return (React.createElement(Navbar, { sticky: 'top', className: 'nav' },
        React.createElement(Navbar.Brand, { className: 'text-white navbrand' },
            React.createElement("span", { className: 'mega' }, "Mega"),
            "Max",
            ' ',
            React.createElement("span", { className: 'subhead' }, "Tic-Tac-Toe")),
        React.createElement(Button, { onClick: demoToast, className: 'button nav-right' }, "Login")));
};
export default Nav;
