import React from 'react';
import { borderClasses, smallMap, bigMap } from '../helpers';
var Space = function (props) {
    var click = props.click, position = props.position, size = props.size, player = props.player, pvp = props.pvp, draw = props.draw, winPos = props.winPos;
    var mapper = size === 'small' ? smallMap : bigMap;
    var borders = mapper(position);
    var classes = borderClasses(borders, size) + ' space';
    if (draw) {
        classes += ' draw';
    }
    else if (winPos) {
        if (pvp || (player === 'x')) {
            classes += ' green';
        }
        else {
            classes += ' red';
        }
    }
    return (React.createElement("div", { onClick: function () { return click(position); }, className: classes }, player));
};
export default Space;
