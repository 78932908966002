import React, { useEffect, useState } from "react";
import { Button, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
var Options = function (props) {
    var reset = props.reset, started = props.started, change = props.change;
    var _a = useState('pvp'), mode = _a[0], setMode = _a[1];
    var _b = useState('small'), size = _b[0], setSize = _b[1];
    var changeMode = function (value) {
        setMode(value);
    };
    var changeSize = function (value) {
        setSize(value);
    };
    useEffect(function () {
        change({ mode: mode, size: size });
    }, [mode, size]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'options' },
            React.createElement(Button, { disabled: !started, onClick: function () { return reset(); }, className: 'button reset grp' }, "Reset"),
            React.createElement(ToggleButtonGroup, { className: 'grp', onChange: changeMode, type: 'radio', name: 'mode-options', defaultValue: 'pvp' },
                React.createElement(ToggleButton, { variant: mode === 'ai' ? 'outline-primary' : 'primary', id: 'tbg-radio-1', value: 'pvp' }, "PVP"),
                React.createElement(ToggleButton, { variant: mode === 'pvp' ? 'outline-danger' : 'danger', id: 'tbg-radio-2', value: 'ai' }, "vs AI")),
            React.createElement(ToggleButtonGroup, { className: 'grp', onChange: changeSize, type: 'radio', name: 'size-options', defaultValue: 'small' },
                React.createElement(ToggleButton, { variant: size === 'large' ? 'outline-primary' : 'primary', id: 'tbg-radio-3', value: 'small' }, "3x3"),
                React.createElement(ToggleButton, { variant: size === 'small' ? 'outline-success' : 'success', id: 'tbg-radio-4', value: 'large' }, "5x5")))));
};
export default Options;
