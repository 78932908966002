import React from 'react';
import { ToastContainer } from 'react-toastify';
import Nav from './Nav';
import Board from './components/Board';
var App = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(ToastContainer, null),
        React.createElement(Nav, null),
        React.createElement("div", { className: 'game' },
            React.createElement(Board, null))));
};
export default App;
